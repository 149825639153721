.object-fit-fix img {
  object-fit: cover;
  object-position: center; }

.object-fit-fix.transparent_child {
  background-size: cover !important; }
  .object-fit-fix.transparent_child img {
    opacity: 0; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[role=button],
input,
input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=number],
input[type=search],
input[type=url] {
  border-radius: 0px !important; }

@media screen and (min-width: 768px) {
  .knoxweb-header-logo-left .fl-icon-group {
    text-align: right; } }

@media screen and (min-width: 768px) {
  .knoxweb-header-logo-left .fl-menu {
    text-align: right; } }

.primary-navigation button.fl-menu-mobile-toggle {
  background-color: rgba(255, 255, 255, 0.5);
  min-width: 100%;
  padding: 1em 0 !important;
  text-transform: uppercase; }
  .primary-navigation button.fl-menu-mobile-toggle .fl-menu-mobile-toggle-label {
    font-size: 25px; }
  .primary-navigation button.fl-menu-mobile-toggle.fl-active, .primary-navigation button.fl-menu-mobile-toggle:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: #333333; }
