body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[role=button],
input,
input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=number],
input[type=search],
input[type=url] {
  border-radius: 0px !important;
}